import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

//importacion de imagenes
import ContactoFoto from "../img/contactoFoto.png";

//importacion de iconos
import { IoIosMail } from "react-icons/io";

export function Contacto() {
  return (
    <Container>
      <img src={ContactoFoto} alt="NexoTests" className="img-fluid rounded" />
      <div className="text-center color-principal-texto w-100 fs-1 fw-bold my-5">
        <h2 className="fs-1">
          CONTACTO
        </h2>
        <IoIosMail />
      </div>
      <Row className="d-flex justify-content-center color-principal rounded py-5 my-5">
        <Col md={11}>
          <Form
            action="https://formspree.io/f/xleqenpq"
            method="POST"
            className="d-flex justify-content-center align-items-center flex-column text-center"
          >
            <Form.Group className="mb-3 w-50">
              <Form.Label className="text-white fs-2">Nombre</Form.Label>
              <Form.Control type="text" name="name" required />
            </Form.Group>

            <Form.Group className="mb-3 w-50">
              <Form.Label className="text-white fs-2">
                Correo Electrónico
              </Form.Label>
              <Form.Control type="email" name="_replyto" required />
            </Form.Group>

            <Form.Group className="mb-3 w-75">
              <Form.Label className="text-white fs-2">Mensaje</Form.Label>
              <Form.Control as="textarea" name="message" rows={3} required />
            </Form.Group>

            <Button variant="light" type="submit" className="color-secundario">
              Enviar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
